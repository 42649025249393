import React, { Suspense, useState, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'
// import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header, { Mobile_Pages } from '../components/Header'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Home from './Home'
import PoolsOverview from './Pool/PoolsOverview'
import TokensOverview from './Token/TokensOverview'
import TopBar from 'components/Header/TopBar'
import { RedirectInvalidToken } from './Token/redirects'
import { LocalLoader } from 'components/Loader'
import PoolPage from './Pool/PoolPage'
import { ExternalLink, TYPE } from 'theme'
import { useActiveNetworkVersion, useSubgraphStatus } from 'state/application/hooks'
import { DarkGreyCard } from 'components/Card'
import { SUPPORTED_NETWORK_VERSIONS } from 'constants/networks'
import { DEFAULT_NETWORK } from 'constants/chains'
import { useIsMobile } from 'hooks/useScreenSize'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
  min-height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  position: fixed;
  justify-content: space-between;
  z-index: 2;
  background:rgba(28,26,66, 1);
`

const BodyWrapper = styled.div<{ warningActive?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  margin-top: ${({ warningActive }) => (warningActive ? '140px' : '100px')};
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  > * {
    max-width: 1200px;
  }

  @media (max-width: 1080px) {
    padding-top: 2rem;
    margin-top: 52px;
  }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const Hide1080 = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  @media (max-width: 1080px) {
    display: none;
  }
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  padding: 1rem;
  color: white;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`


const MobileBottomBar = styled.div`
  z-index: 1020;
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  justify-content: space-between;
  padding: 4px 8px;
  height:52px;
  background: ${({ theme }) => theme.bg0_FK};
  border-top: 1px solid ${({ theme }) => theme.bg1_FK}; 
`;

const BLOCK_DIFFERENCE_THRESHOLD = 30

export default function App() {
  // pretend load buffer
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1300)
  }, [])

  // update network based on route
  // TEMP - find better way to do this
  const location = useLocation()
  const [activeNetwork, setActiveNetwork] = useActiveNetworkVersion()

  useEffect(() => {
    if (location.pathname === '/') {
      // setActiveNetwork(EthereumNetworkInfo)
      setActiveNetwork(DEFAULT_NETWORK()) 
    } else {
      SUPPORTED_NETWORK_VERSIONS.map((n) => {
        if (location.pathname.includes(n.route.toLocaleLowerCase())) {
          setActiveNetwork(n)
        }
      })
    }
  }, [location.pathname, setActiveNetwork])

  // subgraph health
  const [subgraphStatus] = useSubgraphStatus()

  // const showNotSyncedWarning =
  //   subgraphStatus.headBlock && subgraphStatus.syncedBlock && activeNetwork === OptimismNetworkInfo
  //     ? subgraphStatus.headBlock - subgraphStatus.syncedBlock > BLOCK_DIFFERENCE_THRESHOLD
  //     : false
  const showNotSyncedWarning = false

  const [dismiss, setDismiss] = useState(true)

  const ismobile = useIsMobile()

  return (
    <Suspense fallback={null}>
      {/* <Route Component={GoogleAnalyticsReporter} />*/}
      {/* <Route Component={DarkModeQueryParamReader} />  */}
      {loading ? (
        <LocalLoader fill={true} />
      ) : (
        <AppWrapper>
          <URLWarning />
          <HeaderWrapper>
            {showNotSyncedWarning && (
              <WarningWrapper>
                <WarningBanner>
                  {`Warning: 
                  Data has only synced to  block ${subgraphStatus.syncedBlock} (out of ${subgraphStatus.headBlock}). Please check back soon.`}
                </WarningBanner>
              </WarningWrapper>
            )}
            {
              !dismiss && (
                <Hide1080 hide={dismiss}>
                  <TopBar onDismiss={() => { setDismiss(true) }} />
                </Hide1080>
              )
            }

            <Header />

          </HeaderWrapper>
          {subgraphStatus.available === false ? (
            <AppWrapper>
              <BodyWrapper>
                <DarkGreyCard style={{ maxWidth: '340px' }}>
                  <TYPE.label>
                    The Graph hosted network which provides data for this site is temporarily experiencing issues. Check
                    current status{' '}
                  </TYPE.label>
                </DarkGreyCard>
              </BodyWrapper>
            </AppWrapper>
          ) : (
            <BodyWrapper warningActive={showNotSyncedWarning}>
              <Popups />
              <Routes>
                <Route path="/:networkID?/pools/:address" element={<PoolPage />} />
                <Route path="/:networkID?/pools" element={<PoolsOverview />} />
                <Route path="/:networkID?/tokens/:address" element={<RedirectInvalidToken />} />
                <Route path="/:networkID?/tokens" element={<TokensOverview />} />
                <Route path="/:networkID?" element={<Home />} />
              </Routes>
              <Marginer />
            </BodyWrapper>
          )}

          {
            ismobile ? (
              <MobileBottomBar>
                <Mobile_Pages />
              </MobileBottomBar>
            ) : <></>
          }


        </AppWrapper>
      )}
    </Suspense>
  )
}
