import { useActiveNetworkVersion, useClients } from 'state/application/hooks'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

export const SUBGRAPH_HEALTH = gql`
  query health {
    blocks(first: 1, orderBy: number, orderDirection: desc) {
      id
      number
      timestamp
      parentHash
    }
  }
`
interface HealthResponse {
  blocks: {
    id: string,
    number: string,
    timestamp: string,
    parentHash: string,
  }[]
}



/**
 * Fetch top addresses by volume
 */
export function useFetchedSubgraphStatus(): {
  available: boolean | null
  syncedBlock: number | undefined
  headBlock: number | undefined
} {
  // const [activeNetwork] = useActiveNetworkVersion()
  const { blockClient } = useClients()
  const { loading, error, data } = useQuery<HealthResponse>(SUBGRAPH_HEALTH, { client: blockClient })


  // if (loading) {
  //   return {
  //     available: null,
  //     syncedBlock: undefined,
  //     headBlock: undefined,
  //   }
  // }

  // if ((!loading) || error) {
  //   return {
  //     available: false,
  //     syncedBlock: undefined,
  //     headBlock: undefined,
  //   }
  // }

  const syncedBlock = data ? parseFloat(data.blocks[0].number) : undefined
 
  return {
    available: syncedBlock ? true : false,
    syncedBlock: syncedBlock,
    headBlock: syncedBlock,
  }
}
 