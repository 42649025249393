import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import TokenPage from './TokenPage'
import { isAddress } from 'ethers/lib/utils'

export function RedirectInvalidToken() {
  // const {
  //   match: {
  //     params: { address },
  //   },
  // } = props
  const {address:pAddress} = useParams(); //{search.id}
  const address=pAddress?.toLowerCase()!

  if (!isAddress(address)) {
    return <Navigate to={`/`} />
  }
  return <TokenPage />
}
