import { LINEA_MainNetworkInfo } from "./networks"

export enum SupportedChainId {
  // MAINNET = 1, 

  // ARBITRUM_ONE = 42161,
  // ARBITRUM_RINKEBY = 421611, 
  // BASE = 8453, 

  ZKSYNC_ERA = 324,
  ZKSYNC_GOERLI = 280,

  LINEA = 59144,
  LINEA_GOERLI = 59140,

}


export const DEFAULT_CHAINID = SupportedChainId.LINEA // SupportedChainId.ZKSYNC_ERA

export const DEFAULT_NETWORK = function () {
  return LINEA_MainNetworkInfo
}
 