import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { darken, transitions } from 'polished'
import styled from 'styled-components'
import Menu from '../Menu'
import Row, { RowFixed, RowBetween } from '../Row'
import SearchSmall from 'components/Search'
import NetworkDropdown from 'components/Menu/NetworkDropdown'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { networkPrefix } from 'utils/networkPrefix'
import { AutoColumn } from 'components/Column'
import { useLocation } from 'react-router-dom'
import { ExternalLink } from 'theme'
import useTheme from 'hooks/useTheme'
import { Moon, Sun } from 'react-feather';
import { useDarkModeManager } from 'state/user/hooks'
import { useIsMobile } from 'hooks/useScreenSize'
import { Button } from 'rebass'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  box-shadow: 0 1px 3px rgba(50,14,88,0.12), 0 1px 2px rgba(50,14,88,0.24);

  background: ${({ theme }) => theme.bg0_FK};

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    padding: 0.5rem 1rem;
    width: calc(100%);
    position: relative;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  @media (max-width: 1080px) {
    display: none;
  }
`

const HeaderRow = styled(RowFixed)`
  @media (max-width: 1080px) {
    width: 100%;
  }
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  @media (max-width: 1080px) {
    padding: 0.5rem;
    justify-content: flex-end;
  } ;
`

const Title = styled(NavLink)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'active'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color:rgba(254,254,254,0.6);
  font-size: 1rem;
  width: fit-content;
  margin: 0 6px;
  padding: 8px 12px;
  font-weight: 500;
 
  :hover {
    border-radius: 12px;
    color: ${({ theme }) => darken(0.1, theme.text1_FK)};
    background-color: ${({ theme }) => theme.bg2_2_FK};
  }

  &.active {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.bg2_FK};
    color: ${({ theme }) => theme.text1_FK};
  }

`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const SmallContentGrouping = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 1080px) {
    display: initial;
  }
`

export const RowBetweenMobile = styled(Row)`
  justify-content: end;
`

export default function Header() {
  const [activeNewtork] = useActiveNetworkVersion()
  const location = useLocation()
  const theme = useTheme()
  const [mode, updateMode] = useDarkModeManager()
  const [isUpdate, setIsUpdate] = useState(false)
  const ismobile = useIsMobile()

  const [openSearch, setOpenSearch] = useState(false)
  function toggleOpenSearch() {
    setOpenSearch(!openSearch)
  }

  return (
    <HeaderFrame>

      {
        !ismobile ? <>
          <HeaderRow>
            <Title to={networkPrefix(activeNewtork)}>
              <ExternalLink href="https://fikaswap.io" target=''>
                <UniIcon>
                  <img height={'26px'} src={'/logo@2x.png'} alt="logo" />
                </UniIcon>
              </ExternalLink>

            </Title>
            <Header_Pages />
          </HeaderRow>

          <HeaderControls>
            <SearchSmall />
            &nbsp; &nbsp;
            <NetworkDropdown />
            {/* <Menu /> */}
            {
              !mode ?
                <Moon color={theme.text1_FK} size={30} onClick={() => { updateMode(); setIsUpdate(!isUpdate) }} style={{ cursor: 'pointer' }} />
                :
                <Sun color={theme.text1_FK} size={30} onClick={() => { updateMode(); setIsUpdate(!isUpdate) }} style={{ cursor: 'pointer' }} />
            }

          </HeaderControls>
        </>
          : <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>

            {
              openSearch ? (
                <>
                  <Button onClick={toggleOpenSearch} style={{ background: 'transparent', padding: '0px' }}>
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3463" width="22" height="22"><path d="M631.168 183.168a42.666667 42.666667 0 0 1 62.826667 57.621333l-2.496 2.709334L423.04 512l268.48 268.501333a42.666667 42.666667 0 0 1 2.496 57.621334l-2.496 2.709333a42.666667 42.666667 0 0 1-57.621333 2.496l-2.709334-2.496-298.666666-298.666667a42.666667 42.666667 0 0 1-2.496-57.621333l2.496-2.709333 298.666666-298.666667z"
                      fill="#f6f2f2" p-id="3464"></path></svg>
                  </Button>
                  &nbsp;
                  <SearchSmall />
                </>

              ) : <>
                <Title to={networkPrefix(activeNewtork)} style={{flex: '1' }}>
                  <ExternalLink href="https://fikaswap.io" target=''>
                    <UniIcon>
                      <img height={'24px'} src={'/logo@2x.png'} alt="logo" />
                    </UniIcon>
                  </ExternalLink>

                </Title>
                <div style={{ flex: '1' }}>
                  <NetworkDropdown />
                </div>

                <div style={{ flex: '1', display: 'flex', gap: '12px',justifyContent:'end' }}>
                  <Button onClick={toggleOpenSearch} style={{ background: 'transparent', padding: '0px' }}>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      data-cy="magnifying-icon"
                    >
                      <path
                        d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M21 21L16 16" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Button>

                  {
                    !mode ?
                      <Moon color={theme.text1_FK} size={22} onClick={() => { updateMode(); setIsUpdate(!isUpdate) }} style={{ cursor: 'pointer' }} />
                      :
                      <Sun color={theme.text1_FK} size={22} onClick={() => { updateMode(); setIsUpdate(!isUpdate) }} style={{ cursor: 'pointer' }} />
                  }
                </div>

              </>
            }

          </div>
      }

    </HeaderFrame>
  )
}

export function Mobile_Pages() {
  return (
    <HeaderRow>
      <Header_Pages />
      {/* <Menu /> */}
    </HeaderRow>
  )
}

function Header_Pages() {
  const [activeNewtork] = useActiveNetworkVersion()
  return (
    <HeaderLinks>
      <StyledNavLink
        id={`pool-nav-link`}
        to={networkPrefix(activeNewtork)}
      // isActive={location.pathname === '/'}
      >
        Overview
      </StyledNavLink>
      <StyledNavLink id={`Pools-nav-link`} to={networkPrefix(activeNewtork) + 'pools'}>
        Pools
      </StyledNavLink>
      <StyledNavLink id={`Tokens-nav-link`} to={networkPrefix(activeNewtork) + 'tokens'}>
        Tokens
      </StyledNavLink>

      <Menu />

    </HeaderLinks>
  )
}