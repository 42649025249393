import React, { useState } from 'react'
import styled from 'styled-components'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { CloseIcon, ExternalLink, TYPE, theme } from 'theme'
import { useEthPrices } from 'hooks/useEthPrices'
import { formatDollarAmount } from 'utils/numbers'
import Polling from './Polling'
import { useActiveNetworkVersion } from '../../state/application/hooks'
import { SupportedNetwork } from '../../constants/networks'
import useTheme from 'hooks/useTheme'
import { Moon, Sun } from 'react-feather';

const Wrapper = styled.div` 
  background: ${({ theme }) => theme.bg6_FK};
  padding: 10px 20px; 
    margin: 6px auto;
    width: 96%;
    border-radius: 12px;
    border: 1px solid rgba(51,16,89,1);
`

const Item = styled(TYPE.main)`
  font-size: 12px; 
`

const StyledLink = styled(ExternalLink)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1_FK};
`

const TopBar = ({ onDismiss }: { onDismiss: () => void }) => {
  const ethPrices = useEthPrices()
  const [activeNetwork] = useActiveNetworkVersion()
  const theme = useTheme()

  const [modal, setModal] = useState(true)

  return (
    <Wrapper>
      <RowBetween>
        <Polling />
        <AutoRow gap="6px">
          <RowFixed>
            <Item color={'text2_FK'}>Eth Price:</Item>
            <Item fontWeight="700" ml="4px" color={'text1_FK'}>
              {formatDollarAmount(ethPrices?.current)}
            </Item>
          </RowFixed>
        </AutoRow>
        <AutoRow gap="6px" style={{ justifyContent: 'flex-end', gap: '10px' }}>
          {
            modal ?
              <Sun color={theme.text1_FK} size={20} onClick={() => { setModal(!modal) }} style={{cursor:'pointer'}} /> :
              <Moon color={theme.text1_FK} size={20} onClick={() => { setModal(!modal) }} style={{cursor:'pointer'}} /> 
          } 
          <StyledLink href="https://app.fikaswap.io" target='' style={{ background: theme.bg2_FK, padding: '2px 10px', lineHeight: '20px', borderRadius: '6px' }}>App</StyledLink>
          <CloseIcon onClick={onDismiss} color={theme.text1_FK} style={{ padding: '2px', borderRadius: '6px', background: theme.bg2_FK }} />
        </AutoRow>
      </RowBetween>
    </Wrapper>
  )
}

export default TopBar
