import React, { StrictMode, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, HashRouter } from 'react-router-dom'
// import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { linea_MainClient, zksync_eraClient } from './apollo/client';
import { Provider } from 'react-redux'
import store from './state'
import UserUpdater from './state/user/updater'
import ProtocolUpdater from './state/protocol/updater'
import TokenUpdater from './state/tokens/updater'
import PoolUpdater from './state/pools/updater'
import ApplicationUpdater from './state/application/updater'
import ListUpdater from './state/lists/updater'
import App from 'pages/App';
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'

function Updaters() { 
 
  return (
    <>
      <ListUpdater />
      <UserUpdater />
      <ProtocolUpdater />
      <TokenUpdater />
      <PoolUpdater />
      <ApplicationUpdater />
    </>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ApolloProvider client={linea_MainClient}>
    <Provider store={store}>
      <Updaters />
      <ThemeProvider>
        <HashRouter>
          <FixedGlobalStyle />
          <ThemedGlobalStyle />
          <App />
        </HashRouter>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>
  ,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
