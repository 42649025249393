import React, { useMemo } from 'react'
import styled from 'styled-components'
import { isAddress } from 'utils'
import Logo from '../Logo'
import { useCombinedActiveList } from 'state/lists/hooks'
import useHttpLocations from 'hooks/useHttpLocations'
import { useActiveNetworkVersion } from 'state/application/hooks'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import { SupportedChainId as ChainId } from 'constants/chains'

export function chainIdToNetworkName(networkId: ChainId) {
  switch (networkId) {
    // case ChainId.MAINNET:
    //   return 'ethereum'
    case ChainId.ZKSYNC_ERA:
      return 'zksyncera'
    case ChainId.ZKSYNC_GOERLI:
      return 'zksyncgoerli'
    case ChainId.LINEA:
      return 'linea'
    case ChainId.LINEA_GOERLI:
      return 'lineagoerli'
    // case ChainId.ARBITRUM_ONE:
    //   return 'arbitrum'
    // case ChainId.OPTIMISM:
    //   return 'optimism'
    // case ChainId.POLYGON:
    //   return 'polygon'
    // case ChainId.BNB:
    //   return 'smartchain'
    // case ChainId.BASE:
    //   return 'base'
    default:
      return 'ethereum'
  }
}

const getTokenLogoURL = ({ symbol, chainId }: { symbol: string; chainId: ChainId }) => {
  return `https://token.fikaswap.io/tokens/${chainId}/${symbol.toLowerCase()}.png`
}

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text4};
`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  address,
  symbol,
  size = '24px',
  style,
  ...rest
}: {
  address?: string
  symbol: string
  size?: string
  style?: React.CSSProperties
}) {
  const [activeNetwork] = useActiveNetworkVersion()
  const listTokens = useCombinedActiveList()?.[activeNetwork.chainId]

  const checkSummed = isAddress(address)

  const liURI = useMemo(() => {
    if (checkSummed && listTokens?.[checkSummed]) {
      return listTokens?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, listTokens])
  const uriLocations = useHttpLocations(liURI)

  //temp until token logo issue merged
  // const tempSources: { [address: string]: string } = useMemo(() => {
  //   return {
  //     ['0x4dd28568d05f09b02220b09c2cb307bfd837cb95']:
  //       'https://assets.coingecko.com/coins/images/18143/thumb/wCPb0b88_400x400.png?1630667954',
  //   }
  // }, [])

  const srcs: string[] = useMemo(() => {
    // const checkSummed = isAddress(address)

    if (symbol) {
      // const override = tempSources[address]
      return [
        getTokenLogoURL({ symbol: symbol!, chainId: activeNetwork.chainId }),
        // ...uriLocationsOptimism,
        // ...uriLocationsArbitrum,
        // ...uriLocationsPolygon,
        // ...uriLocationsCelo,
        // ...uriLocationsBNB,
        // ...uriLocationsBase,
        ...uriLocations,
        // override,
      ]
    }
    return []
  }, [
    address,
    // tempSources,
    activeNetwork.chainId,
    // uriLocationsOptimism,
    // uriLocationsArbitrum,
    // uriLocationsPolygon,
    // uriLocationsCelo,
    // uriLocationsBNB,
    // uriLocationsBase,
    uriLocations
  ])

  // if (activeNetwork === OptimismNetworkInfo && address === '0x4200000000000000000000000000000000000006') {
  //   return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} {...rest} />
  // }

  return <StyledLogo size={size} srcs={srcs} alt={'token logo'} style={style} {...rest} />
}
